import React, { useState, useEffect, useRef } from 'react';
import { Autocomplete, Box, Chip, Typography } from '@mui/joy';
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome';
import { faFolderBookmark, faClose } from '@fortawesome/pro-solid-svg-icons';
import { useGetSavedSearches } from 'src/hooks';
import { useProspectSearch } from 'src/context';

const SavedSearchAutocomplete = ({ selectedSearches, setSelectedSearches }) => {
  const isInitialMount = useRef(true);
  const { prospectSearch } = useProspectSearch();

  const { data: savedSearches } = useGetSavedSearches();
  const [localSelectedSearches, setLocalSelectedSearches] =
    useState(selectedSearches);

  useEffect(() => {
    const handler = setTimeout(() => {
      if (isInitialMount.current) {
        if (
          localSelectedSearches.length === 0 &&
          selectedSearches.length === 0
        ) {
          setSelectedSearches([prospectSearch]);
          setLocalSelectedSearches([prospectSearch]);
        }
        isInitialMount.current = false; // Mark initial mount as complete
      } else {
        // Sync if values differ
        if (
          JSON.stringify(localSelectedSearches) !==
          JSON.stringify(selectedSearches)
        ) {
          setSelectedSearches(localSelectedSearches);
        }
      }
    }, 300); // Debounce delay of 300ms

    return () => clearTimeout(handler); // Cleanup to avoid multiple timeouts
  }, [
    localSelectedSearches,
    selectedSearches,
    prospectSearch,
    setSelectedSearches,
  ]);

  // Update local state on selection change
  const handleSelectionChange = (event, newValue) => {
    setLocalSelectedSearches(newValue);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        justifyContent: 'center',
        gap: '0.5rem',
        width: '100%',
        minWidth: 210,
      }}
    >
      <Typography
        level="body-sm"
        sx={{
          fontSize: '0.875rem',
          fontWeight: 500,
          textOverflow: 'ellipsis',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          width: '100%',
        }}
      >
        View volume for:
      </Typography>
      <Autocomplete
        value={selectedSearches}
        onChange={handleSelectionChange}
        multiple
        placeholder="Add a Saved Search"
        limitTags={2}
        options={savedSearches || []}
        isOptionEqualToValue={(option, value) => option.id === value.id}
        getOptionLabel={(option) => option.name}
        startDecorator={<Icon icon={faFolderBookmark} />}
        renderTags={(tag, getTagProps) =>
          tag.map((option, index) => (
            <Chip
              key={index}
              endDecorator={<Icon icon={faClose} />}
              variant="plain"
              color="neutral"
              sx={{
                minWidth: 0,
                color: '#009976',
                '& .MuiChip-action': {
                  background: '#D6F6F1 !important',
                  '&:hover': {
                    background: '#CBE9E4 !important',
                  },
                },
              }}
              {...getTagProps({ index })}
            >
              {option.name}
            </Chip>
          ))
        }
        sx={{
          width: '100%',
          minWidth: 200,
          borderRadius: '0.5rem',
        }}
      />
    </Box>
  );
};

export default SavedSearchAutocomplete;
