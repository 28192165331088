import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import { useLocalStorage } from 'src/hooks';

export const SiteActionTypes: { [key: string]: string } = {
  SET_SEARCH: 'SET_SEARCH',
  SET_SELECTED_TYPE: 'SET_SELECTED_TYPE',
  SET_SELECTED_DATE: 'SET_SELECTED_DATE',
  SET_SELECTED_SEARCHES: 'SET_SELECTED_SEARCHES',
};

type SiteState = {
  hcp: {
    search: string;
    selectedDate: {
      lte: string;
      gte: string;
    };
    selectedType: 'claims' | 'patients';
    selectedSearches: MedScout.SavedSearch[];
  };
  center: {
    search: string;
    selectedDate: {
      lte: string;
      gte: string;
    };
    selectedType: 'claims' | 'patients';
    selectedSearches: MedScout.SavedSearch[];
  };
  clinic: {
    search: string;
    selectedDate: {
      lte: string;
      gte: string;
    };
    selectedType: 'claims' | 'patients';
    selectedSearches: MedScout.SavedSearch[];
  };
  hcp_groups: {
    search: string;
    selectedDate: {
      lte: string;
      gte: string;
    };
    selectedType: 'claims' | 'patients';
    selectedSearches: MedScout.SavedSearch[];
  };
};

const sharedState = {
  search: '',
  selectedDate: {
    lte: '2024',
    gte: '2019',
  },
  selectedType: 'claims' as 'claims' | 'patients',
  selectedSearches: [] as MedScout.SavedSearch[],
};

const initialState: SiteState = {
  hcp: {
    ...sharedState,
  },
  center: {
    ...sharedState,
  },
  clinic: {
    ...sharedState,
  },
  hcp_groups: {
    ...sharedState,
  },
};

const SiteContext = createContext<{
  state: SiteState;
  dispatch: React.Dispatch<any>;
}>({
  state: initialState,
  dispatch: () => null,
});

const siteReducer = (state: SiteState, action: any) => {
  const { location } = action.payload;
  switch (action.type) {
    case SiteActionTypes.SET_SEARCH: {
      return {
        ...state,
        [location]: {
          ...state[location],
          search: action.payload.search,
        },
      };
    }
    case SiteActionTypes.SET_SELECTED_TYPE: {
      return {
        ...state,
        [location]: {
          ...state[location],
          selectedType: action.payload.selectedType,
        },
      };
    }
    case SiteActionTypes.SET_SELECTED_DATE: {
      return {
        ...state,
        [location]: {
          ...state[location],
          selectedDate: action.payload.selectedDate,
        },
      };
    }
    case SiteActionTypes.SET_SELECTED_SEARCHES: {
      return {
        ...state,
        [location]: {
          ...state[location],
          selectedSearches: action.payload.selectedSearches,
        },
      };
    }
    default:
      return state;
  }
};

export const SiteProvider = ({ children }: { children: ReactNode }) => {
  // Get the initial state from localStorage or fall back to `initialState`
  const [storedState, setStoredState] = useLocalStorage(
    'pageFilterBar',
    initialState
  );

  // Use the retrieved state to initialize useReducer
  const [state, dispatch] = useReducer(siteReducer, storedState);

  // Save state to localStorage whenever it updates
  useEffect(() => {
    setStoredState(state);
  }, [state]);

  return (
    <SiteContext.Provider value={{ state, dispatch }}>
      {children}
    </SiteContext.Provider>
  );
};

export const useSite = () => {
  const context = useContext(SiteContext);
  if (!context) throw new Error('useSite must be used within a SiteProvider');
  return context;
};
