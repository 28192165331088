import { useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Hydrate } from 'react-query/hydration';
import { useRouter } from 'next/router';
import * as Sentry from '@sentry/nextjs';
import {
  AnalyticsProvider,
  AuthProvider,
  CRMProvider,
  DashboardProvider,
  LayoutControlProvider,
  NotificationProvider,
  ProspectProvider,
  PushToCRMButtonProvider,
  SiteProvider,
  TableProvider,
} from 'src/context';
import { IntegrationsProvider } from 'src/modules/Settings/context';

export const GlobalProvider = ({ children, pageProps }) => {
  const router = useRouter();
  const queryClientState = new QueryClient();
  const [queryClient] = useState(queryClientState);

  function ErrorFallback({ resetErrorBoundary }) {
    resetErrorBoundary();
    return null;
  }

  function errorHandler(error) {
    Sentry.captureMessage('App page error boundary hit');
    Sentry.captureException(error);
  }

  return (
    <AnalyticsProvider>
      <AuthProvider>
        <QueryClientProvider client={queryClient}>
          <Hydrate state={pageProps.dehydratedState}>
            <ErrorBoundary
              FallbackComponent={ErrorFallback}
              onError={errorHandler}
              onReset={() => {
                router.reload();
              }}
            >
              <SiteProvider>
                <LayoutControlProvider>
                  <NotificationProvider>
                    <ProspectProvider>
                      <CRMProvider>
                        <DashboardProvider>
                          <IntegrationsProvider>
                            <PushToCRMButtonProvider>
                              <TableProvider>{children}</TableProvider>
                            </PushToCRMButtonProvider>
                          </IntegrationsProvider>
                        </DashboardProvider>
                      </CRMProvider>
                    </ProspectProvider>
                  </NotificationProvider>
                </LayoutControlProvider>
              </SiteProvider>
            </ErrorBoundary>
          </Hydrate>
          <ReactQueryDevtools />
        </QueryClientProvider>
      </AuthProvider>
    </AnalyticsProvider>
  );
};
