import { Box, Typography } from '@mui/joy';
import React from 'react';

const NewTablePrimaryHeader = ({ title }) => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        alignItems: 'center',
        gap: 'var(--2, 1rem)',
        alignSelf: 'stretch',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: '1 0 0',
        }}
      >
        <Typography
          sx={{
            color: 'var(--text-primary, #171A1C)',
            fontFamily: 'Inter',
            fontSize: '20px',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '155%' /* 31px */,
          }}
        >
          All Affiliated -{' '}
          <Typography
            sx={{
              color: 'var(--secondary-soft-ActiveColor, #00A783)',
              fontFamily: ' Inter',
              fontSize: '20px',
              fontStyle: 'normal',
              fontWeight: '600',
              lineHeight: '155%',
            }}
          >
            {title}
          </Typography>
        </Typography>
        <Typography
          sx={{
            color: 'var(--neutral-400, #9FA6AD)',
            fontFamily: 'Inter',
            fontSize: '12px',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '144%' /* 17.28px */,
          }}
        >
          Show number of patients or claims for your pre-saved targeting
          criteria during a given timeframe.
        </Typography>
      </Box>
    </Box>
  );
};

export default NewTablePrimaryHeader;
