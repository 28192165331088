import { Box, Typography } from '@mui/joy';
import React from 'react';

const TableVolumeHeader = () => {
  return (
    <Box
      sx={{
        display: 'flex',
        height: 'auto',
        alignItems: 'center',
        gap: 'var(--2, 1rem)',
        alignSelf: 'stretch',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          flex: '1 0 0',
        }}
      >
        <Typography
          sx={{
            color: 'var(--text-primary, #171A1C)',
            fontFamily: 'Inter',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '155%' /* 31px */,
          }}
        >
          Volume
        </Typography>
        <Typography
          sx={{
            color: 'var(--neutral-600, #555E68)',
            fontFamily: 'Inter',
            fontSize: '0.75rem',
            fontStyle: 'normal',
            fontWeight: '400',
            lineHeight: '144%' /* 17.28px */,
          }}
        >
          Show number of patients or claims for your pre-saved targeting
          criteria during a given timeframe.
        </Typography>
      </Box>
    </Box>
  );
};

export default TableVolumeHeader;
