import { useEffect, useRef, useState } from 'react';
import {
  Dropdown,
  Menu,
  MenuButton,
  IconButton,
  CircularProgress,
} from '@mui/joy';
import ExportMenuItem from './ExportMenuItem';
import DownloadRoundedIcon from '@mui/icons-material/DownloadRounded';
import { useMediaQuery, useTheme } from '@mui/material';
import { useTable } from 'src/context';
import { useOnClickOutside } from 'src/hooks';

interface GridExportMenuProps {
  onExport: (type: string) => void;
  isExporting?: boolean;
  disabled?: boolean;
}

const GridExportMenu = ({
  onExport,
  isExporting,
  disabled = false,
}: GridExportMenuProps) => {
  const theme = useTheme();
  const menuRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useRef(null);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const [anchorEl, setAnchorEl] = useState(null);
  const { showIcon } = useTable();

  useEffect(() => {
    if (!anchorEl) return;
    setAnchorEl(null);
  }, [isMobile]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setAnchorEl(null);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleExport = (type: string) => {
    onExport(type);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleMenu = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  useOnClickOutside(menuRef, anchorEl, handleClose);

  return (
    <Dropdown>
      {isMobile || showIcon ? (
        <IconButton
          variant="soft"
          color="neutral"
          onClick={toggleMenu}
          sx={{
            fontWeight: 900,
          }}
          disabled={disabled}
        >
          {isExporting ? (
            <CircularProgress size="sm" />
          ) : (
            <DownloadRoundedIcon fontSize="small" />
          )}
        </IconButton>
      ) : (
        <MenuButton
          startDecorator={<DownloadRoundedIcon fontSize="small" />}
          variant="soft"
          color="neutral"
          onClick={toggleMenu}
          sx={{
            fontWeight: 900,
          }}
          loading={isExporting}
          disabled={disabled}
        >
          Export
        </MenuButton>
      )}
      <Menu
        placement="bottom-end"
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        sx={{
          boxShadow: 'sm',
          flexGrow: 0,
          minWidth: 200,
          maxHeight: 300,
          overflow: 'auto',
        }}
        ref={dropdownRef}
      >
        <ExportMenuItem type="csv" onExport={handleExport} />
        <ExportMenuItem type="xlsx" onExport={handleExport} />
      </Menu>
    </Dropdown>
  );
};

export default GridExportMenu;
