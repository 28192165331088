import React from 'react';
import { Box, Container } from '@mui/joy';
import {
  DateSelect,
  TypeSelect,
  SavedSearchAutocomplete,
  SearchInput,
} from './components';
import { useSite, SiteActionTypes } from 'src/context';
import { useRouter } from 'next/router';

const PageFilterBar = () => {
  const router = useRouter();
  const { type: location } = router.query as { type: string };
  const { state, dispatch } = useSite();

  const setSearch = (search) => {
    dispatch({
      type: SiteActionTypes.SET_SEARCH,
      payload: { location, search },
    });
  };

  const setSelectedType = (selectedType) => {
    dispatch({
      type: SiteActionTypes.SET_SELECTED_TYPE,
      payload: { location, selectedType },
    });
  };

  const setSelectedDate = (selectedDate) => {
    dispatch({
      type: SiteActionTypes.SET_SELECTED_DATE,
      payload: { location, selectedDate },
    });
  };

  const setSelectedSearches = (selectedSearches) => {
    dispatch({
      type: SiteActionTypes.SET_SELECTED_SEARCHES,
      payload: { location, selectedSearches },
    });
  };

  return (
    <Container
      maxWidth="xl"
      disableGutters
      sx={{
        display: 'flex',
        gap: '0.5rem',
        minWidth: 0,
      }}
    >
      <SearchInput search={state[location]?.search} setSearch={setSearch} />

      {/* Divider wouldn't show, so we old skool it. */}
      <Box
        sx={{
          height: 'auto',
          width: '1px',
          minWidth: '1px',
          backgroundColor: '#636B744D',
          marginInline: '0.5rem',
        }}
      />
      <SavedSearchAutocomplete
        selectedSearches={state[location]?.selectedSearches}
        setSelectedSearches={setSelectedSearches}
      />
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: '0.5rem',
          width: '100%',
          flex: 2,
        }}
      >
        <TypeSelect
          selectedType={state[location]?.selectedType}
          setSelectedType={setSelectedType}
        />
        <DateSelect
          selectedDate={state[location]?.selectedDate}
          setSelectedDate={setSelectedDate}
        />
      </Box>
    </Container>
  );
};

export default PageFilterBar;
